import axios from '@/plugins/axios';

export default {
  getOrganisationAssetReportNotes(assetReportId, params = {}) {
    return axios.get(`/v1/organisation/asset-reports/${assetReportId}/notes`, params);
  },
  createOrganisationAssetReportNote(assetReportId, data, config = {}) {
    return axios.post(`/v1/organisation/asset-reports/${assetReportId}/notes`, data, config);
  },
  deleteOrganisationAssetReportNote(assetReportId, noteId, config = {}) {
    return axios.delete(`/v1/organisation/asset-reports/${assetReportId}/notes/${noteId}`, config);
  },
};
