import axios from '@/plugins/axios';

export default {
  getOrganisationAssets(config = {}) {
    return axios.get('/v1/organisation/assets', config);
  },
  createOrganisationAsset(data, config = {}) {
    return axios.post('/v1/organisation/assets', data, config);
  },
  getOrganisationAsset(organisationAssetId, config = {}) {
    return axios.get(`/v1/organisation/assets/${organisationAssetId}`, config);
  },
  getOrganisationAssetsCount() {
    return axios.get('/v1/organisation/dashboard/assets-count');
  },
  updateOrganisationAsset(assetId, data, config = {}) {
    return axios.put(`/v1/organisation/assets/${assetId}`, data, config);
  },
  deleteOrganisationAsset(organisationAssetId, config = {}) {
    return axios.delete(`/v1/organisation/assets/${organisationAssetId}`, config);
  },
  createOrganisationAssetMedia(assetId, data, config = {}) {
    return axios.post(`/v1/organisation/assets/${assetId}/media`, data, config);
  },
  deleteOrganisationAssetMedia(assetId, mediaId, config = {}) {
    return axios.delete(`/v1/organisation/assets/${assetId}/media/${mediaId}`, config);
  },
  importOrganisationAssets(data, config = {}) {
    return axios.post('/v1/organisation/assets/import', data, config);
  },
  exportOrganisationAssets(config = {}) {
    const mergedConfig = {
      ...config,
      params: {
        ...config.params,
        export: 'csv',
      },
    };

    return this.getOrganisationAssets(mergedConfig);
  },
};
