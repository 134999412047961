<template>
  <section class="pb-16">
    <ul class="grid xl:grid-cols-8 md:grid-cols-4 grid-cols-2 gap-4">
      <li class="relative" v-for="(image, index) in images" :key="index">
        <a :href="image.url" target="_blank">
            <div class="w-full aspect-w-10 aspect-h-7 bg-gray-100 overflow-hidden">
            <img :src="image.thumb" alt="" class="object-cover">
          </div>
          <p class="mt-2 text-sm font-medium text-gray-900 truncate uppercase">{{ image.name }}</p>
        </a>
        <p class="text-sm font-medium text-gray-500">{{ image.size }}</p>
        <p class="text-xs">{{ image.created_at_diff }}</p>
        <a href="#" class="text-xs text-red-600 link" @click="$emit('deleteMedia', image.id)">{{ $t('delete') }}</a>
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  props: {
    images: {
      type: Array,
      required: true,
    },
  },
};
</script>
