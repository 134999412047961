<template>
  <div class="max-w-4xl">
    <page-heading :heading="$t(modelId ? 'edit' : 'create')">
      <template v-slot:tabs>
        <tabs
          :tabs="tabs"
          :active-tab="activeTab"
          @switchTab="switchTab"
        />
      </template>
    </page-heading>

    <debug>{{ model }}</debug>

    <loading-wrapper>
      <template v-if="activeTab === 'basic'">
        <form-open
          @submit="submit"
          class="grid md:grid-cols-2 gap-4"
        >
          <form-select
            :form-id="formId"
            :label="$t('model.asset_report.asset')"
            :options="data.assets"
            :placeholder="$t('select_placeholder')"
            :required="false"
            form-error-id="asset_id"
            input-id="asset-id"
            v-model="model.asset_id"
          />
          <div>
            <form-text
              :class="{
                'disabled': !!model.asset_id,
              }"
              :form-id="formId"
              :label="$t('model.asset_report.asset_unknown_ref')"
              form-error-id="asset_unknown_ref"
              input-id="asset-unknown-ref"
              v-model="model.asset_unknown_ref"
              :disabled="!!model.asset_id"
            />
            <div class="flex justify-end mt-2" v-if="canCreateAsset">
              <a @click="openCreateAssetModal" class="flex items-center space-x-1 text-sm text-gray-500 link">
                <app-svg class="h-4" svg="exclamation-circle"/>
                <span>{{ $t('asset_report_create_asset') }}</span>
              </a>
            </div>
          </div>
          <form-textarea
            :form-id="formId"
            :label="$t('model.asset_report.ai')"
            :rows="4"
            class="col-span-full"
            form-error-id="ai"
            input-id="ai"
            v-model="model.ai"
          />
          <form-text
            :form-id="formId"
            :label="$t('model.asset_report.action_required')"
            class="col-span-full"
            form-error-id="action_required"
            input-id="action-required"
            type="date"
            v-model="model.action_required"
          />
          <div class="col-span-full flex justify-end">
            <app-button
              :disabled="loading"
              :label="$t(modelId ? 'update' : 'create')"
              :loading="loading"
            />
          </div>
        </form-open>
      </template>
      <template v-if="activeTab === 'location'">
        <div class="grid grid-cols-2 gap-4">
          <div class="col-span-full" v-if="!canUpdateLocation">
            <alert theme="warning">{{ $t('asset_report_location_disabled') }}</alert>
          </div>
          <div class="col-span-full space-y-4">
            <gmap-autocomplete
              :options="{
                fields: [
                  'geometry',
                  'formatted_address',
                ],
              }"
              :placeholder="$t('google_places_autocomplete_placeholder')"
              @place_changed="placeAutocompleteChange"
              class="sm:text-sm appearance-none block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 focus:outline-none focus:ring-app-orange focus:border-app-orange shadow-none"
              ref="googleMapAutocomplete"
              v-if="canUpdateLocation"
            />

            <div class="h-48">
              <gmap-map
                :center="googleMap.mapCenter"
                :clickable="true"
                :zoom="googleMap.zoom"
                @click="gmapClick"
                class="w-full h-full"
                ref="googleMap"
              >
                <gmap-marker
                  :draggable="canUpdateLocation"
                  :key="index"
                  :position="m.position"
                  @dragend="gmapMarkerDragEnd"
                  v-for="(m, index) in googleMap.markers"
                />
              </gmap-map>
            </div>
          </div>

          <form-text
            :form-id="formId"
            :label="$t('model.location.lat')"
            class="hidden"
            form-error-id="location.lat"
            input-id="location.lat"
            v-model="model.location.lat"
          />
          <form-text
            :form-id="formId"
            :label="$t('model.location.lng')"
            class="hidden"
            form-error-id="location.lng"
            input-id="location.lng"
            v-model="model.location.lng"
          />
          <form-textarea
            :form-id="formId"
            :label="$t('model.location.desc')"
            :required="false"
            class="col-span-full"
            form-error-id="location.desc"
            input-id="location.desc"
            v-model="model.location.desc"
            :disabled="!canUpdateLocation"
          />
          <div class="col-span-full flex justify-end">
            <app-button
              :disabled="loading || !canUpdateLocation"
              :label="$t(modelId ? 'update' : 'create')"
              :loading="loading"
              @click="submit"
            />
          </div>
        </div>
      </template>
      <template v-if="activeTab === 'media'">
        <div class="space-y-4">
          <form-files
            :files="media"
            :server="{
              process: createAssetReportMedia,
            }"
            @processfiles="getAndSetAssetReport(modelId)"
            class="w-full"
            name="file"
          />
          <gallery :images="images" @deleteMedia="deleteAssetReportMedia"/>
        </div>
      </template>
      <template v-if="activeTab === 'notes'">
        <notes
          :form-id="formIds.createAssetReportNote"
          :items="notes.items"
          :meta="notes.meta"
          @delete="deleteAssetReportNote"
          @submit="createAssetReportNote"
        />
      </template>
    </loading-wrapper>

    <modal
      :active="modals.createAsset"
      @close="closeCreateAssetModal"
    >
      <div class="flex-1 space-y-4">
        <h2 class="text-2xl font-semibold">{{ $t('create') }}</h2>
        <form-open class="grid gap-4" @submit="createAsset">
          <form-text
            :form-id="formIds.createAsset"
            :label="$t('model.asset.name')"
            form-error-id="name"
            input-id="name"
            v-model="models.asset.name"
          />
          <form-text
            :disabled="true"
            :form-id="formIds.createAsset"
            :label="$t('model.asset.ref')"
            form-error-id="ref"
            icon="qrcode"
            input-id="ref"
            v-model="model.asset_unknown_ref"
          />
          <input type="submit" class="hidden">
        </form-open>
      </div>

      <template v-slot:buttons>
        <app-button
          :disabled="loading"
          :label="$t('create')"
          :loading="loading"
          @click="createAsset"
          class="ml-2"
        />
      </template>
    </modal>
  </div>
</template>

<script>
import _ from 'lodash';
import ApiAssetReportService from '@/services/api/asset_report';
import ApiAssetReportNoteService from '@/services/api/asset_report_note';
import ApiAssetService from '@/services/api/asset';
import Common from '@/mixins/common';
import Gallery from '@/components/Gallery.vue';
import moment from 'moment';
import Notes from '@/components/Notes.vue';
import config from '@/config';
import store from '@/store';

export default {
  components: {
    Gallery,
    Notes,
  },
  computed: {
    canCreateAsset() {
      return !this.model.asset_id
        && this.model.asset_unknown_ref
        && this.$can('manage_assets');
    },
    canUpdateLocation() {
      return !this.modelId || !this.modelHasInitialLocation;
    },
    tabs() {
      return [
        {
          id: 'basic',
          label: this.$t('basic'),
          icon: 'information-circle',
        },
        {
          id: 'location',
          label: this.$t('location'),
          icon: 'location-marker',
          // disabled: !this.canUpdateLocation,
        },
        {
          id: 'media',
          label: this.$t('media'),
          icon: 'photograph',
          disabled: !this.modelId,
        },
        {
          id: 'notes',
          label: this.$t('notes'),
          icon: 'pencil-alt',
          disabled: !this.modelId,
        },
      ];
    },
  },
  data() {
    return {
      activeTab: 'basic',
      data: {
        assets: [],
      },
      formId: 'createEditAssetReport',
      formIds: {
        createAsset: 'createAsset',
        createAssetReportMedia: 'createAssetReportMedia',
        createAssetReportNote: 'createAssetReportNote',
      },
      googleMap: {
        mapCenter: config.app.googleMapDefaults.center,
        markers: [],
        zoom: config.app.googleMapDefaults.zoom,
      },
      images: [],
      media: [],
      modals: {
        createAsset: false,
      },
      modelId: this.$route.params.assetReportId,
      modelHasInitialLocation: false,
      model: {
        location: {},
      },
      models: {
        asset: {},
      },
      notes: {
        items: [],
        meta: {},
      },
    };
  },
  methods: {
    closeCreateAssetModal() {
      this.closeModals('createAsset');
      store.dispatch('validation/clearValidationErrors', {
        namespace: this.formIds.createAsset,
      });
    },
    // eslint-disable-next-line no-unused-vars
    createAssetReportMedia(fieldName, file, metadata, load, error, progress, abort, transfer, options) {
      const formData = new FormData();
      formData.append('file', file, file.name);

      ApiAssetReportService.createOrganisationAssetReportMedia(this.modelId, formData, {
        formId: this.formIds.createAssetReportMedia,
        onUploadProgress: (e) => {
          progress(e.lengthComputable, e.loaded, e.total);
        },
        // showMessage: true,
      })
        .then((response) => {
          const { data } = response.data;

          load(data.uuid);
        })
        .catch((e) => {
          const { data } = e.response;

          error(data.message);
        });

      return {
        abort: () => {
          abort();
        },
      };
    },
    createAssetReportNote(data) {
      ApiAssetReportNoteService.createOrganisationAssetReportNote(this.modelId, data, {
        formId: this.formIds.createAssetReportNote,
      })
        .then(() => {
          this.modelHasInitialLocation = true;
          this.getAssetReportNotes(this.modelId);
        })
        .catch(() => {});
    },
    deleteAssetReportMedia(mediaId) {
      ApiAssetReportService.deleteOrganisationAssetReportMedia(this.modelId, mediaId)
        .then(() => {
          this.getAndSetAssetReport(this.modelId);
        })
        .catch(() => {});
    },
    deleteAssetReportNote(noteId) {
      ApiAssetReportNoteService.deleteOrganisationAssetReportNote(this.modelId, noteId)
        .then(() => {
          this.getAssetReportNotes(this.modelId);
        })
        .catch(() => {});
    },
    getAndSetAssetReport(modelId) {
      ApiAssetReportService.getOrganisationAssetReport(modelId, {
        params: {
          include: [
            'location',
            'media',
          ],
        },
      })
        .then((response) => {
          const { data } = response.data;

          this.model = this.resourceToModel(data);
          this.images = data.images;

          if (data.location) {
            const position = {
              lat: data.location.lat,
              lng: data.location.lng,
            };

            this.setMapMarkerAndCenter(position);
            this.modelHasInitialLocation = true;
          }
        })
        .catch(() => {});
    },
    getAssetReportNotes(modelId) {
      ApiAssetReportNoteService.getOrganisationAssetReportNotes(modelId)
        .then((response) => {
          const { data, meta } = response.data;

          this.notes.items = data;
          this.notes.meta = meta;
        })
        .catch(() => {});
    },
    getOrganisationAssets() {
      return ApiAssetService.getOrganisationAssets({
        params: {
          per_page: -1,
        },
      })
        .then((response) => {
          const { data } = response.data;

          this.data.assets = this.toSelectOptions(data, 'name_friendly');
        })
        .catch(() => {});
    },
    gmapClick(event) {
      if (!this.canUpdateLocation) {
        return;
      }

      this.setMapMarkerAndCenter({
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      });
    },
    gmapMarkerDragEnd(event) {
      if (!this.canUpdateLocation) {
        return;
      }

      this.setMapMarkerAndCenter({
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      });
    },
    openCreateAssetModal() {
      this.openModal('createAsset');
    },
    createAsset() {
      ApiAssetService.createOrganisationAsset({
        name: this.models.asset.name,
        ref: this.model.asset_unknown_ref,
        location: this.model.location,
      }, {
        formId: this.formIds.createAsset,
      })
        .then((response) => {
          const { data } = response.data;

          this.getOrganisationAssets()
            .then(() => {
              this.model.asset_id = data.id;
              this.model.asset_unknown_ref = null;
              this.closeCreateAssetModal();

              // this.submit();
            });
        })
        .catch((e) => {
          const { data, status } = e.response;
          if (status === 422 && 'ref' in data.errors) {
            store.dispatch('messages/addMessage', {
              type: 'warning',
              message: this.$t('asset_report_create_asset_exists'),
            });
            this.closeCreateAssetModal();
          }
        });
    },
    placeAutocompleteChange(place) {
      if (!place) {
        return;
      }

      this.setMapMarkerAndCenter({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        desc: place.formatted_address,
      });

      // this.$refs.googleMapAutocomplete.clear();
    },
    refreshModel() {
      this.getAndSetModel(this.modelId);
    },
    resourceToModel(data) {
      const model = _.pick(data, [
        'ai',
        'asset_id',
        'asset_unknown_ref',
      ]);
      model.action_required = data.action_required ? moment(data.action_required, moment.ISO_8601).format('YYYY-MM-DD') : null;
      model.location = data.location
        ? _.pick(data.location, [
          'desc',
          'lat',
          'lng',
        ])
        : {};

      return model;
    },
    setMapMarkerAndCenter(position) {
      this.googleMap.mapCenter = position;
      this.googleMap.markers = [
        {
          position,
        },
      ];
      this.googleMap.zoom = 12;

      this.model.location = {
        ...this.model.location,
        ...position,
      };
    },
    submit() {
      if (this.modelId) {
        ApiAssetReportService.updateOrganisationAssetReport(this.modelId, this.model, {
          formId: this.formId,
          showMessage: true,
        })
          .then(() => {
            this.getAndSetAssetReport(this.modelId);
          })
          .catch(() => {});
      } else {
        ApiAssetReportService.createOrganisationAssetReport(this.model, {
          formId: this.formId,
          showMessage: true,
        })
          .then((response) => {
            const { data } = response.data;

            this.modelId = data.id;
            this.$router.push({
              name: 'asset_reports.edit',
              params: {
                assetReportId: data.id,
              },
            });
          })
          .catch(() => {});
      }
    },
    switchTab(tab) {
      this.activeTab = tab;
    },
  },
  mixins: [
    Common,
  ],
  mounted() {
    this.getOrganisationAssets();

    if (this.modelId) {
      this.getAndSetAssetReport(this.modelId);
      this.getAssetReportNotes(this.modelId);
    }
  },
};
</script>
