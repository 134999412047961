<template>
  <section>
    <div class="bg-white shadow">
      <div class="px-4 py-6 sm:px-6" v-show="items.length">
        <ul class="space-y-8">
          <li v-for="item in items" :key="item.id">
            <div class="flex space-x-3">
              <div class="flex-shrink-0">
                <span class="inline-flex items-center justify-center h-10 w-10 bg-gray-400">
                  <span class="text-xs font-medium leading-none text-white uppercase">{{ item.user.initials }}</span>
                </span>
              </div>
              <div>
                <h4 class="text-sm font-medium text-gray-900">{{ item.user.name }}</h4>
                <div class="text-sm text-gray-700">
                  <p>{{ item.note }}</p>
                </div>
                <div class="mt-1 text-sm space-x-2">
                  <span class="text-gray-500 font-medium">{{ item.created_at_diff }}</span>
                  <span class="text-gray-500 font-medium">&middot;</span>
                  <button type="button" class="font-medium text-red-600"
                    @click="deleteNote(item.id)"
                  >{{ $t('delete') }}</button>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <pagination
          :meta="meta"
          :page="query.page"
          v-if="meta"
          @pageUpdated="updatePage"
        />
      </div>
      <debug>{{ model }}</debug>
      <div class="bg-gray-50 px-4 py-6 sm:px-6">
        <div class="flex space-x-3">
          <div class="flex-shrink-0">
            <span class="inline-flex items-center justify-center h-10 w-10 bg-gray-400">
              <span class="text-xs font-medium leading-none text-white uppercase">{{ user.initials }}</span>
            </span>
          </div>
          <div class="min-w-0 flex-1">
            <form-open @submit="submit" class="space-y-2">
              <form-textarea :form-id="formId" input-id="note" form-error-id="note" v-model="model.note" @submit="submit"/>
              <div class="flex justify-end">
                <app-button
                  :disabled="loading"
                  :loading="loading"
                />
              </div>
            </form-open>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AppButton from '@/components/AppButton.vue';
import Debug from '@/components/Debug.vue';
import FormOpen from '@/components/FormOpen.vue';
import FormTextarea from '@/components/FormTextarea.vue';
import LoadingState from '@/mixins/loadingState';
import Pagination from '@/components/Pagination.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    AppButton,
    Debug,
    FormOpen,
    FormTextarea,
    Pagination,
  },
  computed: mapGetters({
    user: 'auth/user',
  }),
  data() {
    return {
      model: this.emptyModel(),
      query: {
        page: 1,
      },
    };
  },
  methods: {
    deleteNote(modelId) {
      this.$emit('delete', modelId);
    },
    emptyModel() {
      return {
        note: '',
      };
    },
    submit() {
      this.$emit('submit', this.model);
      this.model = this.emptyModel();
    },
    updatePage(page) {
      this.query.page = page;
      this.$emit('pageUpdated');
    },
  },
  mixins: [
    LoadingState,
  ],
  props: {
    formId: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    meta: {
      type: Object,
    },
  },
};
</script>
